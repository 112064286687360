import React from 'react';
import { Box, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapContainerReport from '../../components/maps/MapContainerReport';
import Myren from '../../styles/icons/reports/myrenlogo.png';
import Watermark from '../../components/report/Watermark';
import { useFormatData } from '../../hook/useFormatData';
import useGeocodedAddresses from '../../hook/useGeocodedAddresses';

const styles = {
  page: {
    width: '210mm',
    minHeight: '297mm',
    padding: '10mm',
    margin: '0 auto',
    backgroundColor: 'white',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
  },
  paperStyle: {
    textAlign: 'center',
    height: '72px',
    border: '1px solid black',
  },
  paperHeader: {
    fontSize: '15px',
    fontWeight: '700',
    backgroundColor: '#D1D5DB',
    height: '30px',
    lineHeight: '30px',
  },
  paperBody: {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '30px',
  },
  listItem: {
    paddingLeft: '10px',
    margin: 0,
  },
  listItemText: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '25px',
    color: '#44403C',
  },  

  listItemTextBold: {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '25px',
    color: '#000000',
  },
  headerText: {
    fontFamily: 'Pretendard',
    fontSize: '26px',
    fontWeight: '700',
    paddingTop: '15px',
    paddingLeft: '10px',
  },
  reportTitle: {
    fontFamily: 'Pretendard',
    fontSize: '23px',
    fontWeight: '700',
    marginTop: '10px',
    display: 'block',
  },
  logoText: {
    fontSize: '40px',
    fontFamily: 'Pretendard',
    fontWeight: 900,
    color: '#2563EB',
    display: 'inline-block',
    lineHeight: '40px',
    letterSpacing: '0.3px',
  },
  logoTextHighlight: {
    fontFamily: ' Pretendard',
    color: '#67E8F9',
    letterSpacing: '0.3px',
  },
};

const formatDecimal = (value) => {
  return value ? parseFloat(value).toFixed(2) : '0';
};


const Page1 = ({ cid, selectedIncident = {}, latitude, longitude, what3Words, insuranceInfo = {}, weatherConditions, rounded, skyCode, accidentSummary }) => {
  const allData = selectedIncident.allData || {};
  const { formatDirection, formatTransmission } = useFormatData();
  const { getAddress, isLoading } = useGeocodedAddresses([selectedIncident]);
  
  const weatherInfo = selectedIncident.weather || {};
  const address = getAddress(selectedIncident);
  const touchData = selectedIncident.touch || {};

  
  if (isLoading) {
    return <CircularProgress />;
  }
  
  return (
    <Box sx={{ ...styles.page, position: 'relative', margin: 0 }}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" component="span" sx={styles.logoText}>
          세이<span style={styles.logoTextHighlight}>프</span>카
        </Typography>
        <Typography variant="h6" component="span" sx={styles.headerText}>
          교통사고보고서
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" component="span" sx={styles.reportTitle}>
          사고일반정보({cid || '-'})
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Paper sx={styles.paperStyle}>
              <Typography variant="body1" sx={styles.paperHeader}>
                휴대폰 터치 횟수
              </Typography>
        <Typography variant="body2" sx={styles.paperBody}>{touchData.count || '0'} 회</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={styles.paperStyle}>
              <Typography variant="body1" sx={styles.paperHeader}>
                사고일자
              </Typography>
              <Typography variant="body2" sx={styles.paperBody}>{selectedIncident.timeStamp || '-'}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
          <Paper sx={styles.paperStyle}>
            <Typography variant="body1" sx={styles.paperHeader}>
              날씨
            </Typography>
            <Typography variant="body2" sx={styles.paperBody}>
              {weatherInfo.skyCode || '-'} 
              {weatherInfo.temperature ? ` / ${weatherInfo.temperature}` : ''}
            </Typography>
          </Paper>
        </Grid>
          <Grid item xs={4}>
            <Paper sx={styles.paperStyle}>
              <Typography variant="body1" sx={styles.paperHeader}>
                사고 장소
              </Typography>
              <Typography variant="body2" sx={styles.paperBody}>위도:{rounded(latitude) || '-'} 경도: {rounded(longitude) || '-'}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper sx={styles.paperStyle}>
              <Typography variant="body1" sx={styles.paperHeader}>
                사고 장소
              </Typography>
              <Typography variant="body2" sx={styles.paperBody}>{address}</Typography>
          </Paper>
          </Grid>
          <Grid item xs={12}>
          <Paper sx={{ textAlign: 'left', height: '265px', border: '1px solid black' }}>
              <Typography variant="body1" sx={{ fontSize: '15px', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#D1D5DB', marginBottom: '5px', height: '30px', lineHeight: '30px' }}>
                사고(상황)개요
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                <ul style={styles.listItem}>
                  <li><Typography variant="body2" sx={styles.listItemText}>● 위험운전판별 타입: <b style={styles.listItemTextBold}>{selectedIncident.accident_type || '-'}</b></Typography></li>
                  <li><Typography variant="body2" sx={styles.listItemText}>● 자동차 속도(km/h): <b style={styles.listItemTextBold}>{selectedIncident.car_speed || '-'} km</b></Typography></li>
                  <li><Typography variant="body2" sx={styles.listItemText}>● 엔진 온도(°C): <b style={styles.listItemTextBold}>{formatDecimal(selectedIncident.engin_load)} °C</b></Typography></li>
                  <li><Typography variant="body2" sx={styles.listItemText}>● 자동차 RPM: <b style={styles.listItemTextBold}>{selectedIncident.car_rpm || '-'} rpm</b></Typography></li>
                  <li><Typography variant="body2" sx={styles.listItemText}>● 사고 시간: <b style={styles.listItemTextBold}>{selectedIncident.timeStamp ? new Date(selectedIncident.timeStamp).toLocaleString([], { hour: '2-digit', minute: '2-digit' }) : '-'}</b></Typography></li>
                  <li><Typography variant="body2" sx={styles.listItemText}>● 자동차 제조사: <b style={styles.listItemTextBold}>{selectedIncident.userCarManufacturer || '-'}</b></Typography></li>
                  <li><Typography variant="body2" sx={styles.listItemText}>● 가입 보험사: <b style={styles.listItemTextBold}>{insuranceInfo.insurance_name || '-'}</b></Typography></li>
                  <li><Typography variant="body2" sx={styles.listItemText}>● 충돌 값(G): <b style={styles.listItemTextBold}>{formatDecimal(selectedIncident.crashValue)} G</b></Typography></li>
                </ul>
                </Grid>
                <Grid item xs={6}>
                  <ul style={styles.listItem}>
                    <li><Typography variant="body2" sx={styles.listItemText}>● 자동차 모델 명: <b style={styles.listItemTextBold}>{selectedIncident.userCarModelName || '-'}</b></Typography></li>
                    <li><Typography variant="body2" sx={styles.listItemText}>● 자동차 번호: <b style={styles.listItemTextBold}>{selectedIncident.userCarNumber || '-'}</b></Typography></li>
                    <li><Typography variant="body2" sx={styles.listItemText}>● 보호자 명: <b style={styles.listItemTextBold}>{selectedIncident.userGuardianName || '-'}</b></Typography></li>
                    <li><Typography variant="body2" sx={styles.listItemText}>● 보호자 전화번호: <b style={styles.listItemTextBold}>{selectedIncident.userGuardianNumber || '-'}</b></Typography></li>
                    <li><Typography variant="body2" sx={styles.listItemText}>● 사용자 ID: <b style={styles.listItemTextBold}>{selectedIncident.userId || '-'}</b></Typography></li>
                    <li><Typography variant="body2" sx={styles.listItemText}>● 사용자 명: <b style={styles.listItemTextBold}>{selectedIncident.userName || '-'}</b></Typography></li>
                    <li><Typography variant="body2" sx={styles.listItemText}>● 보험사 전화번호: <b style={styles.listItemTextBold}>{insuranceInfo.insurance_number || '-'}</b></Typography></li>
                    <li><Typography variant="body2" sx={styles.listItemText}>● 기어 변속기: <b style={styles.listItemTextBold}> {formatTransmission(selectedIncident.transmission)}</b></Typography></li>
                  </ul>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
          <Paper sx={{ textAlign: 'left', height: '150px', border: '1px solid black' }}>
          <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              backgroundColor: '#D1D5DB', 
              height: '30px'
            }}>
              <Typography 
                variant="body1" 
                sx={{ 
                  fontSize: '15px', 
                  fontWeight: 'bold', 
                  color: 'black',
                  marginRight: '10px'
                }}
              >
                사고 요약
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: '12px', 
                  color: '#374151'
                }}
              >
                (본 보고서 요약은 참고 용도입니다.)
              </Typography>
            </Box>
            <ul style={styles.listItem}>
              <li>
              <Typography variant="body2" sx={styles.listItemText}>
                ● 사고 전 데이터 : -5초 ~ 0초 이내에 기록된 속도는 {allData.mfive?.speed ?? '-'}km/h ~ {selectedIncident.car_speed ?? '-'}km/h 였습니다.
              </Typography>
              </li>
              <li>
              <Typography variant="body2" sx={styles.listItemText}>
                  ● 사고 후 데이터 : 0초 ~ 1초 이내에 기록된 속도는 {selectedIncident.car_speed ?? '-'}km/h ~ {allData.pone?.speed ?? '-'}km/h 였습니다.
              </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={styles.listItemText}>
                  ● 날씨 데이터 : {
                    weatherInfo.skyCode ?
                    `당시 날씨는 ${weatherInfo.skyCode}이었고, 강수량은 ${weatherInfo.rainfall || '정보 없음'}였습니다. 풍속은 ${weatherInfo.direction || '-'}으로 ${formatDirection(weatherInfo.custom)}방향입니다.` :
                    '날씨 정보가 없습니다.'
                  }
                </Typography>
              </li>
              <li><Typography variant="body2" sx={styles.listItemText}>● 기어 변속기 : 사고 당시 기어 위치는 {formatTransmission(selectedIncident.transmission)}에 위치하고 있었습니다.</Typography></li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
      </Box>
      {latitude !== null && longitude !== null && !isNaN(latitude) && !isNaN(longitude) ? (
        <Paper elevation={3} sx={{ padding: '2px', marginTop: '12px', marginBottom: '20px', border: '1px solid black', position: 'relative' }}>
          <Box sx={{ width: '100%', height: '315px' }}>
            <MapContainerReport
              key={`${latitude}-${longitude}`}
              lat={latitude}
              lng={longitude}
              level={3}
              markerTitle="Accident Location"
            />
          </Box>
          {what3Words && (
            <Box 
              sx={{ 
                position: 'absolute', 
                bottom: '10px', 
                left: '50%', 
                transform: 'translateX(-50%)', 
                background: 'rgba(255, 255, 255, 0.8)', 
                padding: '10px', 
                borderRadius: '8px', 
                display: 'flex',
                alignItems: 'center', 
                boxShadow: 1 ,
                zIndex: 1
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '8px', color: '#FF0000' }}>
                <LocationOnIcon />
              </Box>
              <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', color: '#000' }}>
                What3Words: {what3Words}
              </Typography>
            </Box>
          )}
        </Paper>
      ) : (
        <CircularProgress />
      )}
    
      <Box display="flex" justifyContent="end" width="100%" mt={2}>
        <img src={Myren} alt="Myren" style={{ width: '97px', height:'19px' }} />
      </Box>
      <Watermark />
    </Box>
  );
};

export default Page1;
