import React from 'react';
import { Box, TextField, Button, Grid } from '@mui/material';
import { useSendNoti } from '../../context/SendNotiContext';
import { database, storage, ref, set, storageRef, uploadBytes, getDownloadURL } from '../../firebase';
import FileUploader from './FileUploader';

const NotificationForm = () => {
  const { notificationData, updateNotificationData, resetNotificationData, files, updateSnackbarState } = useSendNoti();

  const handleChange = (e) => {
    const { id, value } = e.target;
    updateNotificationData(id, value);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentTime = new Date();
    const koreanOffset = 9 * 60;
    const kstTime = new Date(currentTime.getTime() + koreanOffset * 60 * 1000);
    const folderName = kstTime.toISOString().slice(0, 19).replace('T', ' ');

    const newNotificationData = { ...notificationData, timeStamp: folderName };

    if (files.length > 0) {
      const fileUrls = await Promise.all(files.map(async (file) => {
        const filePath = `noti/${folderName}/${file.name}`;
        const fileRef = storageRef(storage, filePath);
        await uploadBytes(fileRef, file);
        return getDownloadURL(fileRef);
      }));
      newNotificationData.fileUrls = fileUrls;
    }

    await set(ref(database, `sendNoti/${folderName}`), newNotificationData);

    updateSnackbarState({
      open: true,
      title: notificationData.title,
      message: notificationData.message,
      severity: 'success'
    });
    resetNotificationData();
  };

  return (
    <Box sx={{ 
      p: 'clamp(10px, 1.85vh, 20px)', 
      backgroundColor: '#FFFFFF', 
      borderRadius: 'clamp(5px, 0.93vh, 10px)', 
      height: 'clamp(400px, 66vh, 800px)' 
    }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="title"
              label="제목"
              variant="outlined"
              fullWidth
              value={notificationData.title}
              onChange={handleChange}
              sx={{
                '& .MuiInputBase-root': { 
                  height: 'clamp(40px, 5.74vh, 70px)',
                  fontSize: 'clamp(14px, 1.85vh, 20px)'
                },
                '& .MuiInputLabel-root': {
                  fontSize: 'clamp(14px, 1.85vh, 20px)'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="message"
              label="메세지"
              variant="outlined"
              fullWidth
              multiline
              rows={8}
              value={notificationData.message}
              onChange={handleChange}
              sx={{
                '& .MuiInputBase-root': { 
                  height: 'clamp(200px, 31.11vh, 400px)',
                  fontSize: 'clamp(14px, 1.85vh, 20px)'
                },
                '& .MuiInputLabel-root': {
                  fontSize: 'clamp(14px, 1.85vh, 20px)'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUploader />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="url"
              label="URL 링크"
              variant="outlined"
              fullWidth
              value={notificationData.url}
              onChange={handleChange}
              sx={{
                '& .MuiInputBase-root': { 
                  height: 'clamp(40px, 5.74vh, 70px)',
                  fontSize: 'clamp(14px, 1.85vh, 20px)'
                },
                '& .MuiInputLabel-root': {
                  fontSize: 'clamp(14px, 1.85vh, 20px)'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ 
                height: 'clamp(40px, 5.74vh, 70px)',
                fontSize: 'clamp(14px, 1.85vh, 20px)'
              }}
            >
              보내기
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NotificationForm;