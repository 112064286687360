import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button
} from '@mui/material';
import { styled } from '@mui/system';
import useWindowDimensions from '../../hook/useWindowDimensions';

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: 'clamp(6px, 0.42vw, 10px)',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: 'clamp(8px, 0.52vw, 12px)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: 'clamp(8px, 0.52vw, 12px)',
    '&:hover': {
      backgroundColor: '#555',
    },
  },
}));

const InsuranceEditModal = ({ open, onClose, company, onSave }) => {
  const [editedCompany, setEditedCompany] = useState(company || {});
  const [focusedField, setFocusedField] = useState(null);
  const { isNarrow } = useWindowDimensions();

  useEffect(() => {
    setEditedCompany(company || {});
  }, [company]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCompany((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(editedCompany);
    onClose();
  };

  const isEdit = Boolean(company?.name);

  const styles = {
    dialog: {
      '& .MuiDialog-paper': {
        borderRadius: 'clamp(12px, 0.83vw, 20px)',
        padding: 'clamp(16px, 1.25vw, 30px)',
        width: isNarrow ? '90vw' : 'clamp(300px, 47.29vw, 1000px)',
        height: isNarrow ? 'auto' : 'clamp(400px, 46.67vh, 600px)',
        maxWidth: 'none',
        overflowY: 'auto',
      }
    },
    title: {
      fontWeight: 'bold',
      color: 'black',
      marginBottom: 'clamp(16px, 1.85vh, 24px)',
      fontSize: 'clamp(20px, 1.46vw, 32px)',
    },
    textField: {
      marginBottom: 'clamp(8px, 1vh, 16px)',
      marginTop: 'clamp(6px, 0.93vh, 12px)',
      height: 'clamp(48px, 5.74vh, 72px)',
      '& .MuiInputBase-input': {
        fontSize: 'clamp(14px, 0.83vw, 18px)',
      },
      '& .MuiInputLabel-root': {
        fontSize: 'clamp(14px, 0.83vw, 18px)',
      },
    },
    button: {
      fontWeight: 'bold',
      color: 'black',
      fontSize: 'clamp(14px, 0.83vw, 18px)',
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      sx={styles.dialog}
    >
      <DialogTitle sx={styles.title}>
        {isEdit ? '보험사 수정' : '보험사 추가'}
      </DialogTitle>
      <CustomDialogContent>
        {['name', 'number', 'aos_url', 'ios_url'].map((field) => (
          <TextField
            key={field}
            label={getFieldLabel(field)}
            name={field}
            variant="outlined"
            fullWidth
            value={editedCompany[field] || ''}
            onChange={handleInputChange}
            onFocus={() => setFocusedField(field)}
            onBlur={() => setFocusedField(null)}
            sx={{
              ...styles.textField,
              '& .MuiInputBase-input': {
                ...styles.textField['& .MuiInputBase-input'],
                color: field === 'name' && isEdit ? '#c4c4c4' : 'black',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#c4c4c4',
              },
            }}
            InputProps={{
              readOnly: field === 'name' && isEdit,
              disabled: field === 'name' && isEdit,
              style: {
                backgroundColor: 'inherit',
              },
            }}
          />
        ))}
      </CustomDialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={styles.button}>
          취소
        </Button>
        <Button onClick={handleSave} sx={styles.button}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getFieldLabel = (field) => {
  switch (field) {
    case 'name':
      return '보험사 이름';
    case 'number':
      return '보험사 전화번호';
    case 'aos_url':
      return 'AOS URL';
    case 'ios_url':
      return 'iOS URL';
    default:
      return '';
  }
};

export default InsuranceEditModal;