import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Box, Checkbox, Divider, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Tabs, Tab
} from '@mui/material';
import { getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved, off, update, remove } from 'firebase/database';
import CustomButton from '../../components/utils/CustomButton'; 
import MemberPagination from '../../components/member/MemberPagination';
import SubLayout from '../../components/layout/SubLayout';
import useWindowDimensions from '../../hook/useWindowDimensions';

const NotificationPage = () => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const { isNarrow } = useWindowDimensions();

  useEffect(() => {
    const db = getDatabase();
    const accidentListRef = ref(db, 'adminNoti/accidentList');
    const requestReportRef = ref(db, 'adminNoti/requestReport');
    const csRef = ref(db, 'adminNoti/cs');

    const handleNotificationUpdate = (data, type) => {
      const userId = data.key;
      const notificationsData = data.val();
      const newNotifications = Object.keys(notificationsData).map(timeStamp => ({
        ...notificationsData[timeStamp],
        id: `${type}/${userId}/${timeStamp}`,
        type,
        userId,
        requestTime: timeStamp,
        accidentTime: notificationsData[timeStamp].timeStamp
      }));
      setNotifications(prev => {
        const updated = prev.filter(n => n.type !== type || n.userId !== userId);
        return [...updated, ...newNotifications].sort((a, b) => new Date(b.requestTime) - new Date(a.requestTime));
      });
    };

    onChildAdded(accidentListRef, data => handleNotificationUpdate(data, 'accidentList'));
    onChildChanged(accidentListRef, data => handleNotificationUpdate(data, 'accidentList'));
    onChildRemoved(accidentListRef, data => handleNotificationUpdate(data, 'accidentList'));

    onChildAdded(requestReportRef, data => handleNotificationUpdate(data, 'requestReport'));
    onChildChanged(requestReportRef, data => handleNotificationUpdate(data, 'requestReport'));
    onChildRemoved(requestReportRef, data => handleNotificationUpdate(data, 'requestReport'));

    onChildAdded(csRef, data => handleNotificationUpdate(data, 'cs'));
    onChildChanged(csRef, data => handleNotificationUpdate(data, 'cs'));
    onChildRemoved(csRef, data => handleNotificationUpdate(data, 'cs'));

    setLoading(false);

    return () => {
      off(accidentListRef);
      off(requestReportRef);
      off(csRef);
    };
  }, []);

  useEffect(() => {
    setFilteredNotifications(notifications.filter(notification => {
      if (tabIndex === 0) return true;
      return notification.type === (tabIndex === 1 ? 'accidentList' : tabIndex === 2 ? 'requestReport' : 'cs');
    }));
  }, [notifications, tabIndex]);

  const handleMarkAsRead = (id) => {
    const db = getDatabase();
    update(ref(db, `adminNoti/${id}`), { read: true });
  };

  const handleDeleteNotification = (id) => {
    const db = getDatabase();
    remove(ref(db, `adminNoti/${id}`));
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  const handleSelectNotification = (id) => {
    setSelectedNotifications((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
    );
  };

  const handleDeleteSelected = () => {
    selectedNotifications.forEach((id) => handleDeleteNotification(id));
    setSelectedNotifications([]);
  };

  const handleMarkSelectedAsRead = () => {
    selectedNotifications.forEach((id) => handleMarkAsRead(id));
    setSelectedNotifications([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };


  const isSelected = (id) => selectedNotifications.includes(id);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredNotifications.map((n) => n.id);
      setSelectedNotifications(newSelecteds);
      return;
    }
    setSelectedNotifications([]);
  };

  const getChipLabel = (type) => {
    switch (type) {
      case 'accidentList':
        return '사고';
      case 'requestReport':
        return '보고서 요청';
      case 'cs':
        return '문의';
      default:
        return '';
    }
  };

  const getNotificationBody = (notification) => {
    switch (notification.type) {
      case 'accidentList':
        return notification.address;
      case 'requestReport':
        return `${notification.address}`;
      case 'cs':
        return notification.email;
      default:
        return '';
    }
  };

  const getNotificationStatus = (notification) => {
    return notification.read ? '읽음' : '읽지 않음';
  };

  const getNotificationStatusStyle = (notification) => {
    return {
      color: notification.read ? 'grey' : 'green',
    };
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const styles = {
    headerBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 'clamp(10px, 1vh, 20px)',
    },
    title: {
      fontWeight: 'bold', 
      fontSize: 'clamp(24px, 2vw, 36px)',
    },
    tabBox: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '0.2vh solid #E5E7EB',
      color: 'black',
      marginBottom: '1vh',
    },
   
    tab: {
      color: '#6B7280',
      fontWeight: 'bold',
      fontSize: 'clamp(13px, 1.2vw, 24px)',
      '&.Mui-selected': {
        color: '#141414',
      },
    },
    buttonBox: {
      display: 'flex',
      gap: 'clamp(8px, 1vw, 16px)',
      marginBottom: isNarrow ? 'clamp(10px, 1vh, 20px)' : 0,
    },
    button: {
      height: 'clamp(32px, 4vh, 48px)', 
      fontSize: 'clamp(12px, 1.4vh, 16px)',
    },
    tableBox: {
      overflowX: isNarrow ? 'auto' : 'hidden',
      overflowY: 'auto',
      width: '100%',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: '0',
    },
    headerCell: {
      color: '#6B7280',
      fontWeight: 'bold',
      padding: 'clamp(6px, 0.5vw, 10px)',
      height: 'clamp(40px, 5.7vh, 60px)',
      fontSize: 'clamp(12px, 1vw, 15px)',
    },
    tableCell: {
      borderBottom: 'none',
      height: 'clamp(40px, 5.7vh, 60px)',
      padding: 'clamp(6px, 0.5vw, 10px)',
      color: '#141414',
      fontSize: 'clamp(12px, 1vw, 16px)',
      fontWeight: 'bolder',
    },
    checkbox: {
      '& .MuiSvgIcon-root': { 
        width: 'clamp(12px, 1vw, 1vw)',
        height: 'clamp(12px, 1vw, 1vw)',
      }
    },
    paginationBox: {
      display: 'flex',
      justifyContent: 'center',
      padding: 'clamp(8px, 1vh, 16px)',
      boxSizing: 'border-box',
      background: 'white',
    },
  };

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ mt: '4vh', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <SubLayout>
      <Box sx={styles.headerBox}>
        <Typography variant="h4" sx={styles.title}>
          알림
        </Typography>
      </Box>
      <Box sx={styles.tabBox}>
        <Tabs 
          value={tabIndex} 
          onChange={(e, newValue) => setTabIndex(newValue)} 
          aria-label="notification tabs"
        >
          <Tab label="전체" sx={styles.tab} />
          <Tab label="사고" sx={styles.tab} />
          <Tab label="보고서 요청" sx={styles.tab} />
          <Tab label="문의" sx={styles.tab} />
        </Tabs>
        <Box sx={styles.buttonBox}>
          <CustomButton sx={styles.button} onClick={handleMarkSelectedAsRead} disabled={selectedNotifications.length === 0}>
            선택 읽음 
          </CustomButton>
          <CustomButton sx={styles.button} onClick={handleDeleteSelected} disabled={selectedNotifications.length === 0}>
            선택 삭제
          </CustomButton>
        </Box>
      </Box>
      <Box sx={styles.tableBox}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" align="center" sx={styles.headerCell}>
                <Checkbox
                  indeterminate={selectedNotifications.length > 0 && selectedNotifications.length < filteredNotifications.length}
                  checked={filteredNotifications.length > 0 && selectedNotifications.length === filteredNotifications.length}
                  onChange={handleSelectAllClick}
                  sx={styles.checkbox}
                />
              </TableCell>
              <TableCell align="center" sx={styles.headerCell}>타입</TableCell>
              <TableCell align="center" sx={styles.headerCell}>사용자 ID</TableCell>
              <TableCell align="center" sx={styles.headerCell}>내용</TableCell>
              <TableCell align="center" sx={styles.headerCell}>알림 시간</TableCell>
              <TableCell align="center" sx={styles.headerCell}>사고 시간</TableCell>
              <TableCell align="center" sx={styles.headerCell}>상태</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredNotifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((notification) => (
              <TableRow key={notification.id} selected={isSelected(notification.id)}>
                <TableCell padding="checkbox" align="center" sx={styles.tableCell}>
                  <Checkbox
                    edge="start"
                    checked={isSelected(notification.id)}
                    tabIndex={-1}
                    disableRipple
                    onClick={() => handleSelectNotification(notification.id)}
                    sx={styles.checkbox}
                  />
                </TableCell>
                <TableCell align="center" sx={styles.tableCell}>{getChipLabel(notification.type)}</TableCell>
                <TableCell align="center" sx={styles.tableCell}>{notification.userId}</TableCell>
                <TableCell align="center" sx={styles.tableCell}>{getNotificationBody(notification)}</TableCell>
                <TableCell align="center" sx={styles.tableCell}>{notification.requestTime || 'N/A'}</TableCell>
                <TableCell align="center" sx={styles.tableCell}>{notification.accidentTime}</TableCell>
                <TableCell align="center" sx={{...styles.tableCell, ...getNotificationStatusStyle(notification)}}>
                  {getNotificationStatus(notification)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Divider sx={{ my: 'clamp(8px, 1vh, 16px)' }} />
      <Box sx={styles.paginationBox}>
        <MemberPagination
          count={Math.ceil(filteredNotifications.length / rowsPerPage)}
          page={page + 1}
          onChange={handleChangePage}
        />
      </Box>
      <Snackbar
        open={snackbar.open}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        autoHideDuration={6000}
      />
    </SubLayout>
  );
};

export default NotificationPage;